<template>
  <LoadingPage v-if="loaded" />
  <div v-else>
    <h5 class="font-weight-light text-uppercase mb-4">
      {{ $t("groupConfigurationEditPage.title") }}
    </h5>
    <div class="card-body border-white pt-1">
      <div class="d-flex justify-content-center my-1 mt-4 pt-4">
        <b-form-group>
          <b-form-radio-group
            id="display_option"
            :options="display_options"
            v-model="option_selected"
            buttons
            name="display_option"
          ></b-form-radio-group>
        </b-form-group>
      </div>
    </div>

    <div
      class="card-body d-flex align-items-center justify-content-between bg-light mb-4"
    >
      <div class="d-flex align-items-center">
        <div class="form-group mr-4" v-if="option_selected == 'new_menu'">
          <label>{{ $t("groupConfigurationPage.group") }}</label>
          <select
            class="form-control"
            id="groups"
            v-model="menu.sales_channels_group_id"
            @change="changes = true"
          >
            <option value="">{{ $t("orderStatus.selectOption") }}</option>
            <option
              v-for="(group, index) in groups"
              :key="index"
              :value="group.id"
            >
              {{ group.name }}
            </option>
          </select>
        </div>

        <div class="form-group mr-4" v-if="option_selected == 'new_menu'">
          <label>{{ $t("status") }}</label>
          <select
            class="form-control"
            id="status"
            v-model="menu.is_active"
            @change="changes = true"
            :class="{ 'is-invalid': errors['is_active'] }"
          >
            <option value="">{{ $t("orderStatus.selectOption") }}</option>
            <option value="true">
              {{ $t("groupConfigurationPage.active") }}
            </option>
            <option value="false">
              {{ $t("groupConfigurationPage.inactive") }}
            </option>
          </select>
          <div
            class="invalid-feedback"
            v-for="(error, index) in errors['is_active']"
            :key="index"
          >
            {{ error }}
          </div>
        </div>
        <div class="form-group mr-4" v-if="option_selected == 'old_menu'">
          <label>{{ $t("orderStatus.selectOption") }}</label>
          <select
            class="form-control"
            id="menus"
            v-model="old_menu_config"
            @change="changes = true"
          >
            <!-- <option value="">Select menu</option> -->
            <option
              v-for="(menu, index) in menus"
              :key="index"
              :value="menu.id"
            >
              {{ menu.sales_channels_group_name }}
            </option>
          </select>
        </div>
      </div>

      <div v-if="option_selected == 'new_menu'">
        <a
          type="button"
          class="button-show-modal"
          data-toggle="modal"
          data-target="#countries"
          v-on:click="initializeCountries()"
        >
          <i class="fas fa-globe-americas"></i>
          {{ $t("createGroupConfigurationPage.countries") }}
        </a>
      </div>
    </div>

    <div class="d-flex justify-content-between">
      <div>
        <div
          style="width: 90px"
          class="button-seller-sales-channel"
          v-on:click="seller_visibility = !seller_visibility"
          v-if="seller_visibility == false && option_selected == 'new_menu'"
        >
          <i class="fas fa-plus"></i>
          {{ $t("createGroupConfigurationPage.showSeller") }}
        </div>
        <div
          class="button-seller-sales-channel"
          v-on:click="sales_channels_visibility = !sales_channels_visibility"
          v-if="
            sales_channels_visibility == false && option_selected == 'new_menu'
          "
        >
          <i class="fas fa-plus"></i>
          {{ $t("createGroupConfigurationPage.showSalesChannels") }}
        </div>
      </div>
      <div v-if="option_selected == 'new_menu'">
        <div
          class="button-seller-sales-channel"
          v-if="option_selected_macro_micro == 'MACRO'"
          v-on:click="option_selected_macro_micro = 'MICRO'"
        >
          <i class="fas fa-exchange-alt"></i> {{ $t("showMicro") }}
        </div>
        <div
          class="button-seller-sales-channel"
          v-else
          v-on:click="option_selected_macro_micro = 'MACRO'"
        >
          <i class="fas fa-exchange-alt"></i> {{ $t("showMacro") }}
        </div>
      </div>
    </div>

    <table
      class="table table-hover table-bordered"
      v-if="option_selected == 'new_menu'"
    >
      <thead>
        <tr>
          <th scope="col" colspan="1"></th>
          <th scope="col" colspan="3">
            {{ $t("createGroupConfigurationPage.customer") }}
          </th>
          <th scope="col" colspan="2" v-if="seller_visibility">
            {{ $t("seller") }} (
            <a href="#" v-on:click="seller_visibility = !seller_visibility">{{
              $t("createGroupConfigurationPage.hide")
            }}</a>
            )
          </th>
          <th scope="col" colspan="2" v-if="sales_channels_visibility">
            {{ $t("salesChannel") }} (
            <a
              href="#"
              v-on:click="
                sales_channels_visibility = !sales_channels_visibility
              "
              >hide</a
            >
            )
          </th>
        </tr>
      </thead>
      <tbody>
        <tr style="font-weight: bold">
          <td class="text-left">
            {{ $t("createGroupConfigurationPage.checkpoints") }}
          </td>
          <td>{{ $t("whatsapp") }}</td>
          <td>{{ $t("email") }}</td>
          <td>{{ $t("sms") }}</td>
          <td v-if="seller_visibility">{{ $t("email") }}</td>
          <td v-if="seller_visibility">{{ $t("settingsPage.webhook") }}</td>
          <td v-if="sales_channels_visibility">{{ $t("email") }}</td>
          <td v-if="sales_channels_visibility">
            {{ $t("settingsPage.webhook") }}
          </td>
        </tr>
        <tr v-for="(checkpoint, index) in menu.menu_checkpoints" :key="index">
          <template
            v-if="
              option_selected_macro_micro == 'MACRO' &&
              checkpointType(checkpoint.id) == option_selected_macro_micro
            "
          >
            <td class="text-left">
              <b>{{ checkIdCheckpoint(checkpoint.id) }}</b>
            </td>

            <td
              v-on:click="changeValueMenu(index, 'whats_customer')"
              class="text-success font-weight-bold"
              v-if="checkpoint.whats_customer == true"
            >
              {{ $t("yes") }}
            </td>
            <td
              v-on:click="changeValueMenu(index, 'whats_customer')"
              class="text-danger font-weight-bold"
              v-else
            >
              {{ $t("no") }}
            </td>

            <td
              v-on:click="changeValueMenu(index, 'mail_customer')"
              class="text-success font-weight-bold"
              v-if="checkpoint.mail_customer == true"
            >
              {{ $t("yes") }}
            </td>
            <td
              v-on:click="changeValueMenu(index, 'mail_customer')"
              class="text-danger font-weight-bold"
              v-else
            >
              {{ $t("no") }}
            </td>

            <td
              v-on:click="changeValueMenu(index, 'sms_customer')"
              class="text-success font-weight-bold"
              v-if="checkpoint.sms_customer == true"
            >
              {{ $t("yes") }}
            </td>
            <td
              v-on:click="changeValueMenu(index, 'sms_customer')"
              class="text-danger font-weight-bold"
              v-else
            >
              {{ $t("no") }}
            </td>

            <template v-if="seller_visibility">
              <td
                v-on:click="changeValueMenu(index, 'mail_seller')"
                class="text-success font-weight-bold"
                v-if="checkpoint.mail_seller == true"
              >
                {{ $t("yes") }}
              </td>
              <td
                v-on:click="changeValueMenu(index, 'mail_seller')"
                class="text-danger font-weight-bold"
                v-else
              >
                {{ $t("no") }}
              </td>
            </template>

            <template v-if="seller_visibility">
              <td
                v-on:click="changeValueMenu(index, 'webhook_seller')"
                class="text-success font-weight-bold"
                v-if="checkpoint.webhook_seller == true"
              >
                {{ $t("yes") }}
              </td>
              <td
                v-on:click="changeValueMenu(index, 'webhook_seller')"
                class="text-danger font-weight-bold"
                v-else
              >
                {{ $t("no") }}
              </td>
            </template>

            <template v-if="sales_channels_visibility">
              <td
                v-on:click="changeValueMenu(index, 'mail_schannel')"
                class="text-success font-weight-bold"
                v-if="checkpoint.mail_schannel == true"
              >
                {{ $t("yes") }}
              </td>
              <td
                v-on:click="changeValueMenu(index, 'mail_schannel')"
                class="text-danger font-weight-bold"
                v-else
              >
                {{ $t("no") }}
              </td>
            </template>

            <template v-if="sales_channels_visibility">
              <td
                v-on:click="changeValueMenu(index, 'webhook_schannel')"
                class="text-success font-weight-bold"
                v-if="checkpoint.webhook_schannel == true"
              >
                {{ $t("yes") }}
              </td>
              <td
                v-on:click="changeValueMenu(index, 'webhook_schannel')"
                class="text-danger font-weight-bold"
                v-else
              >
                {{ $t("no") }}
              </td>
            </template>
          </template>
          <template v-if="option_selected_macro_micro == 'MICRO'">
            <td class="text-left">
              <b>{{ checkIdCheckpoint(checkpoint.id) }}</b>
              {{ getMicroTitle(checkpoint.id) }}
            </td>
            <td
              v-on:click="changeValueMenu(index, 'whats_customer')"
              class="text-success font-weight-bold"
              v-if="checkpoint.whats_customer == true"
            >
              {{ $t("yes") }}
            </td>
            <td
              v-on:click="changeValueMenu(index, 'whats_customer')"
              class="text-danger font-weight-bold"
              v-else
            >
              {{ $t("no") }}
            </td>

            <td
              v-on:click="changeValueMenu(index, 'mail_customer')"
              class="text-success font-weight-bold"
              v-if="checkpoint.mail_customer == true"
            >
              {{ $t("yes") }}
            </td>
            <td
              v-on:click="changeValueMenu(index, 'mail_customer')"
              class="text-danger font-weight-bold"
              v-else
            >
              {{ $t("no") }}
            </td>

            <td
              v-on:click="changeValueMenu(index, 'sms_customer')"
              class="text-success font-weight-bold"
              v-if="checkpoint.sms_customer == true"
            >
              {{ $t("yes") }}
            </td>
            <td
              v-on:click="changeValueMenu(index, 'sms_customer')"
              class="text-danger font-weight-bold"
              v-else
            >
              {{ $t("no") }}
            </td>

            <template v-if="seller_visibility">
              <td
                v-on:click="changeValueMenu(index, 'mail_seller')"
                class="text-success font-weight-bold"
                v-if="checkpoint.mail_seller == true"
              >
                {{ $t("yes") }}
              </td>
              <td
                v-on:click="changeValueMenu(index, 'mail_seller')"
                class="text-danger font-weight-bold"
                v-else
              >
                {{ $t("no") }}
              </td>
            </template>

            <template v-if="seller_visibility">
              <td
                v-on:click="changeValueMenu(index, 'webhook_seller')"
                class="text-success font-weight-bold"
                v-if="checkpoint.webhook_seller == true"
              >
                {{ $t("yes") }}
              </td>
              <td
                v-on:click="changeValueMenu(index, 'webhook_seller')"
                class="text-danger font-weight-bold"
                v-else
              >
                {{ $t("no") }}
              </td>
            </template>

            <template v-if="sales_channels_visibility">
              <td
                v-on:click="changeValueMenu(index, 'mail_schannel')"
                class="text-success font-weight-bold"
                v-if="checkpoint.mail_schannel == true"
              >
                {{ $t("yes") }}
              </td>
              <td
                v-on:click="changeValueMenu(index, 'mail_schannel')"
                class="text-danger font-weight-bold"
                v-else
              >
                {{ $t("no") }}
              </td>
            </template>

            <template v-if="sales_channels_visibility">
              <td
                v-on:click="changeValueMenu(index, 'webhook_schannel')"
                class="text-success font-weight-bold"
                v-if="checkpoint.webhook_schannel == true"
              >
                {{ $t("yes") }}
              </td>
              <td
                v-on:click="changeValueMenu(index, 'webhook_schannel')"
                class="text-danger font-weight-bold"
                v-else
              >
                {{ $t("no") }}
              </td>
            </template>
          </template>
        </tr>
      </tbody>
    </table>

    <group-configuration-view v-else :paramId="menuId" :loadPrevent="false" />

    <div class="mt-4 pb-4 float-right" v-if="option_selected == 'new_menu'">
      <a
        type="button"
        class="button-primary"
        v-on:click="updateConfiguration()"
      >
        {{ $t("update") }}
      </a>
    </div>

    <div
      class="modal fade"
      id="countries"
      tabindex="-1"
      role="dialog"
      aria-labelledby="countriesModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="countriesModalLabel">
              {{ $t("createGroupConfigurationPage.countries") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              v-on:click="initializeCountries()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="not-chosen">{{
                    $t("orderStatus.selectOption")
                  }}</label>
                  <select
                    multiple
                    class="form-control"
                    id="not-chosen"
                    v-model="temp_chosen_countries"
                  >
                    <option
                      v-for="(country, index) in countries"
                      :key="index"
                      :value="index"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                </div>
                <button
                  class="float-right button-edit-config"
                  v-on:click="addfromselectionToChosenCountries()"
                >
                  <i class="fas fa-angle-double-right"></i>
                </button>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="chosen">{{
                    $t("createGroupConfigurationPage.chosenCountries")
                  }}</label>
                  <select
                    multiple
                    class="form-control"
                    id="chosen"
                    v-model="temp_countries"
                  >
                    <option
                      v-for="(country, index) in chosen_countries"
                      :key="index"
                      :value="index"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                </div>
                <button
                  class="button-edit-config"
                  v-on:click="removeSelectionToCountries()"
                >
                  <i class="fas fa-angle-double-left"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="button-light"
              data-dismiss="modal"
              v-on:click="initializeCountries()"
            >
              {{ $t("cancel") }}
            </button>
            <button
              type="button"
              class="button-primary"
              data-dismiss="modal"
              v-on:click="saveChangeCountries()"
            >
              {{ $t("update") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Esse modal não utilizado???? -->
    <div
      class="modal fade"
      id="leaving-confirmation"
      tabindex="-1"
      role="dialog"
      aria-labelledby="leaving-confirmation-label"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="leaving-confirmation-labe">
              {{ $t("groupConfigurationEditPage.discardChanges") }}?
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{ $t("groupConfigurationEditPage.leaveWithoutSaving") }}?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="button-light"
              data-dismiss="modal"
              v-on:click="answer_before_leaving_the_page = true"
            >
              {{ $t("groupConfigurationEditPage.leave") }}
            </button>
            <button
              type="button"
              class="button-primary"
              data-dismiss="modal"
              v-on:click="answer_before_leaving_the_page = false"
            >
              {{ $t("groupConfigurationEditPage.stay") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckpointService from "../../services/CheckpointService";
/* import MenuConfigurationService from "../../services/MenuConfigurationService"; */
import SalesChannelsManagementService from "../../services/SalesChannelManagementService";
import CountryService from "../../services/CountryService";
import GroupsConfigurationService from "../../services/GroupsConfigurationService";
import GroupConfigurationView from "./GroupConfigurationView.vue";
import LoadingPage from "@/components/loadings/LoadingPage.vue";
//import $ from "jquery";

const checkpointService = new CheckpointService();
/* const menuConfigurationService= new MenuConfigurationService(); */
const salesChannelManagementService = new SalesChannelsManagementService();
const countryService = new CountryService();
const groupsConfigurationService = new GroupsConfigurationService();

export default {
  name: "CreateGroupConfiguration",
  components: { GroupConfigurationView, LoadingPage },
  data: () => {
    return {
      menuId: "",
      loaded: true,
      menu: {},
      seller_visibility: false,
      sales_channels_visibility: false,
      option_selected: "new_menu",
      option_selected_macro_micro: "MACRO",
      groups: "",
      checkpoints: "",
      menus: "",
      countries: [],
      chosen_countries: [],
      search_country: "",
      search_chosen_countries: "",
      menu_config: "",
      old_menu_config: "",
      temp_chosen_countries: [],
      temp_countries: [],
      backup_chosen_countries: [],
      answer_before_leaving_the_page: false,
      changes: false,
      errors: [],
    };
  },
  beforeMount() {
    /* menuConfigurationService.getMenus().then(response => { */
    groupsConfigurationService.getConfigs().then((response) => {
      this.menus = response.data.data;
      salesChannelManagementService.getGroups().then((response) => {
        this.groups = response.data.data;
        checkpointService.getCheckpoints().then((response) => {
          this.checkpoints = response.data.data;
          groupsConfigurationService
            .getConfig(this.$route.params.id)
            .then((response) => {
              this.menu = response.data.data;
              countryService.getCountries().then((response) => {
                this.countries = response.data.data;
                this.preFillChosenCountryObject();
                this.displayByOrderGivenByPierre();
                this.loaded = false;
              });
            });
        });
      });
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.changes) {
      const answer = window.confirm(
        "Do you really want to leave? you have unsaved changes!"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    verifNameOfCheckPoint(cp) {
      for (let k = 0; k < this.checkpoints.length; k++) {
        if (
          this.menu.menu_checkpoints[cp].id === this.checkpoints[k].id &&
          this.checkpoints[k].name === "IN TRANSIT"
        ) {
          return -1;
        }
      }

      return cp;
    },
    displayByOrderGivenByPierre() {
      let order = [
        "NEW",
        "ORDER INFORMATION RECEIVED",
        "READY TO SHIP",
        "PICKUP",
        "EXCEPTION IN PICKUP",
        "SHIPPED",
        "IN TRANSIT",
        "OUT_FOR_DELIVERY",
        "FAILED_ATTEMPT",
        "DELIVERED",
        /*"PARTIAL DELIVERY",*/ "EXCEPTION",
        /*"PARTIAL DELIVERY FAILED",*/ "EXPIRED",
        "PENDING",
        "REVERSE",
      ];

      let newOrder = [];
      for (let i = 0; i < order.length; i++) {
        for (let j = 0; j < this.menu.menu_checkpoints.length; j++) {
          for (let k = 0; k < this.checkpoints.length; k++) {
            if (
              this.checkpoints[k].name.includes(order[i]) &&
              this.menu.menu_checkpoints[j].id === this.checkpoints[k].id
            ) {
              newOrder.push(this.menu.menu_checkpoints[j]);
            }
          }
        }
      }
      this.menu.menu_checkpoints = newOrder;
      //return ;
    },

    updateConfiguration() {
      this.changes = false;
      this.menu.countries = this.chosen_countries;
      if (this.option_selected == "new_menu") {
        this.menu.use_existing_menu = false;
        this.menu.new_menu = this.menu.menu_checkpoints;
        this.menu.menu_id ? delete this.menu.menu_id : "";
      } else {
        this.menu.use_existing_menu = true;
        this.menu.menu_id = this.old_menu_config;
        this.menu.new_menu ? delete this.menu.new_menu : "";
      }
      for (let i = 0; i < this.menu.countries.length; i++) {
        this.menu.countries[i] = this.menu.countries[i].alpha2_code;
      }
      groupsConfigurationService
        .updateConfig(this.$route.params.id, this.menu)
        .then(() => {
          this.$router.push("/notifications/group_configuration");
        })
        .catch((err) => {
          this.errors = err.response.data.messages[0];
        });
    },
    getMicroTitle(cp) {
      for (let i = 0; i < this.checkpoints.length; i++) {
        if (
          cp === this.checkpoints[i].id &&
          this.checkpoints[i].type === "MICRO"
        )
          return this.checkpoints[i].title;
      }
    },
    checkIdCheckpoint(cp) {
      for (let i = 0; i < this.checkpoints.length; i++) {
        if (cp === this.checkpoints[i].id) return this.checkpoints[i].name;
      }
    },
    checkpointType(cp) {
      for (let i = 0; i < this.checkpoints.length; i++) {
        if (cp === this.checkpoints[i].id) return this.checkpoints[i].type;
      }
    },
    changeValueMenu(index, key) {
      this.changes = true;
      this.menu.menu_checkpoints[index][key] =
        !this.menu.menu_checkpoints[index][key];
    },

    addfromselectionToChosenCountries() {
      if (this.temp_chosen_countries.length > 0) {
        this.changes = true;
        for (let i = this.temp_chosen_countries.length - 1; i >= 0; i--) {
          this.chosen_countries.push(
            this.countries[this.temp_chosen_countries[i]]
          );
          this.countries.splice(this.temp_chosen_countries[i], 1);
        }
        this.temp_chosen_countries = [];
      }
    },

    removeSelectionToCountries() {
      if (this.temp_countries.length > 0) {
        this.changes = true;
        for (let i = this.temp_countries.length - 1; i >= 0; i--) {
          this.countries.push(this.chosen_countries[this.temp_countries[i]]);
          this.chosen_countries.splice(this.temp_countries[i], 1);
        }
        this.temp_countries = [];
      }
    },

    initializeCountries() {
      this.chosen_countries = JSON.parse(
        JSON.stringify(this.backup_chosen_countries)
      );
    },

    preFillChosenCountryObject() {
      for (let i = 0; i < this.menu.countries.length; i++) {
        for (let j = 0; j < this.countries.length; j++) {
          if (this.menu.countries[i] === this.countries[j].alpha2_code) {
            this.chosen_countries.push(this.countries[j]);
            this.countries.splice(j, 1);
          }
        }
      }
      this.backup_chosen_countries = JSON.parse(
        JSON.stringify(this.chosen_countries)
      );
    },

    saveChangeCountries() {
      this.backup_chosen_countries = JSON.parse(
        JSON.stringify(this.chosen_countries)
      );
    },
  },
  computed: {
    country_search_list() {
      return this.countries.filter((country) => {
        return country.name
          .toLowerCase()
          .includes(this.search_country.toLowerCase());
      });
    },

    chosen_country_search_list() {
      return this.chosen_countries.filter((country) => {
        return country.name
          .toLowerCase()
          .includes(this.search_chosen_countries.toLowerCase());
      });
    },
    display_options() {
      return [
        {
          text: this.$t("createGroupConfigurationPage.newMenu"),
          value: "new_menu",
        },
        {
          text: this.$t("createGroupConfigurationPage.existingMenu"),
          value: "old_menu",
        },
      ];
    },
  },
  watch: {
    old_menu_config(val) {
      this.menuId = val;
    },
  },
};
</script>

<style lang="scss">
td {
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}

.button-edit-config {
  background-color: var(--bgLight);
  border: 1px solid var(--gray);
}

.button-edit-config:hover {
  border: 1px solid black;
}

.button-show-modal {
  text-decoration: none;
  border: 1px solid var(--gray);
  color: var(--darkGray);
  padding: 10px 20px;
  border-radius: 5px;
  transition: 500ms;
}

.button-show-modal i {
  font-size: 14px;
  margin-right: 10px;
}

.button-show-modal:link {
  text-decoration: none;
}

.button-show-modal:hover {
  text-decoration: none;
  background-color: var(--primary);
  color: var(--white);
}

.button-close {
  text-decoration: none;
  border: 1px solid var(--gray);
  color: var(--darkGray);
  padding: 10px 20px;
  border-radius: 5px;
  transition: 500ms;
}

.button-close i {
  font-size: 14px;
  margin-right: 10px;
}

.button-close:link {
  text-decoration: none;
}

.button-close:hover {
  text-decoration: none;
  border: 1px solid var(--shadow);
}

.button-add-update {
  text-decoration: none;
  border: 1px solid var(--shadow);
  background-color: var(--primary);
  color: var(--bgLight);
  padding: 10px 20px;
  border-radius: 5px;
  transition: 500ms;
}
.button-add-update i {
  font-size: 14px;
  margin-right: 10px;
}
.button-add-update:link {
  text-decoration: none;
}
.button-add-update:hover {
  text-decoration: none;
  background-color: var(--bgLight);
  border: 1px solid var(--primary);
  color: var(--primary);
}

.button-add-update-green {
  text-decoration: none;
  border: 1px solid var(--lightGreen);
  background-color: var(--lightGreen);
  color: var(--bgLight);
  padding: 10px 20px;
  border-radius: 5px;
  transition: 500ms;
}
.button-add-update-green i {
  font-size: 14px;
  margin-right: 10px;
}
.button-add-update-green:link {
  text-decoration: none;
}
.button-add-update-green:hover {
  text-decoration: none;
  background-color: var(--bgLight);
  border: 1px solid var(--lightGreen);
  color: var(--lightGreen);
}
</style>
